

export const formTypes = {
    inputChange: '[form] Input changed',
    initForm: '[form] Init Form structure',
    cleanForm: '[form] Clean Values',
    fillFormData: '[form] Fill form data',
    SwitchPermissionAll: '[Permissions] Switch all permission',
    // FillSelect: '[Permissions] Fill select',
    addCollectionRegister: '[form] Add collection register',
    removeCollectionRegister: '[form] Remove collection register',
    editCollectionRegister: '[form] Edit collection register'
}