import { types } from "@redux/types/types"

const initialState = {
  registers: [],
  endPoint: null,
  checkRows: []
}

const list = (state = initialState, action) => {
  switch (action.type) {
    case types.load:
      // If server returns an error, you can handle it here
      if (!action.payload.data) action.payload.data = []
      return {
        ...state,
        endPoint: action.payload.endPoint,
        registers: [...action.payload.data]
      }
    case types.delete:
      return {
        ...state,
        registers: state.registers.filter(reg => reg.id !== action.payload)
      }
    case types.deleteRepair:
      state.registers[action.payload.index].repairs = state.registers[action.payload.index].repairs.filter(reg => reg.id !== action.payload.id)

      return {
        ...state,
        registers: [...state.registers]
      }
    case types.cleaningAll:
      return {
        ...initialState
      }
    case types.change:
      const { index, name, valor } = action.payload

      const newReg = {
        ...state.registers[index],
        [name]: valor
      }

      return {
        ...state,
        registers: state.registers.map((reg, i) => (
          i === index ? newReg : reg
        ))
      }

    case types.halfLoad:
      return {
        ...state,
        registers:
          state.registers.map((reg) => {
            return reg.id === action.payload.parentId ? { ...reg, details: action.payload.data } : reg
          })

      }
    case types.checkRows:
      if (action.payload.type !== 'list') {
        if (action.payload.checked === true) {
          return {
            ...state,
            [action.payload.name]: [...state[action.payload.name], action.payload.value]
          }
        } else {
          return {
            ...state,
            [action.payload.name]: [...state[action.payload.name].filter(s => s !== action.payload.value)]
          }
        }
      } else {
        return {
          ...state,
          [action.payload.name]: action.payload.value
        }
      }

    default:
      return state
  }
}

export default list