import { selectTypes } from "../../types/selects/types"

// ** Initial State
const initialState = {}

const select = (state = initialState, action) => {
    switch (action.type) {

        case selectTypes.addSelectOptions:
            return {
                ...state,
                [action.payload.key]: action.payload.options
            }

        default:
            return state
    }
}

export default select
