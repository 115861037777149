
export const types = {

    load: '[Load] Loading registers from table',
    delete: '[Delete] Delete register',
    deleteRepair: '[Delete] Delete repair register',
    cleaningAll: '[Cleaning] Cleaning register from redux',
    change: '[Change] Changing value',
    checkRows: '[Change] Check Rows',
    halfLoad: '[Form] Half Load'
}