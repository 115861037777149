import { formTypes } from "../../types/form/types"

// ** Initial State
const initialState = {}

const handleNestedState = (keys, initialData, action) => {
    const nested = (data, index = 0) => {
        if (index === keys.length - 1) {
            if (action.payload.removeRepeater) return { ...data, [keys[index]]: [...data[keys[index]].filter((f, i) => i !== action.payload.position)] }
            else if (action.payload.addRepeater) return { ...data, [keys[index]]: [...data[keys[index]], action.payload.value] }
        }
        if (index === keys.length) {
            if (action.payload.trim) return { ...data, [action.payload.name]: [...data[action.payload.name].filter(f => !!!action.payload.value.some(a => a === f))] }
            if (action.payload.merge) return { ...data, [action.payload.name]: [...data[action.payload.name], ...action.payload.value] }
            if (action.payload.enumeration) return { ...data, [action.payload.name]: action.payload.value }
            if (action.payload.customMTM) return [...action.payload.value.map(a => ({ id: a.value, name: a.label }))]
            return { ...data, [action.payload.name]: action.payload.value }
        }

        if ((index !== keys.length - 1) && data[keys[index]] instanceof Array) {
            data[keys[index]][keys[index + 1]] = nested(data[keys[index]][keys[index + 1]], index + 2)
            return { ...data, [keys[index]]: [...data[keys[index]]] }
        }
        return { ...data, [keys[index]]: nested(data[keys[index]], ++index) }
    }
    return nested(initialData)
}

const form = (state = initialState, action) => {

    switch (action.type) {

        case formTypes.inputChange:
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }

        case formTypes.initForm:
            return {
                ...action.payload
            }

        case formTypes.addCollectionRegister:
            return { ...handleNestedState(action.payload.key, state, action) }

        case formTypes.removeCollectionRegister:
            return { ...handleNestedState(action.payload.key, state, action) }

        case formTypes.editCollectionRegister:
            return { ...handleNestedState(action.payload.key, state, action) }

        case formTypes.cleanForm:
            return initialState

        case formTypes.fillFormData:
            return {
                ...state,
                ...action.payload
            }

        case formTypes.SwitchPermissionAll:
            const newPermision = Object.entries(state[action.payload.key]).map((el) => {
                return { id: [el[0]], value: action.payload.value }
            })

            const newP = newPermision.reduce(
                (obj, item) => Object.assign(obj, { [item.id]: item.value }), {})

            return {
                ...state,
                [action.payload.key]: {
                    ...newP
                }
            }
        // case formTypes.FillSelect:
        //     return {
        //         ...state,
        //         [action.payload.id]: {
        //             ...state[action.payload.id],
        //             select: action.payload.value
        //         }
        //     }


        default:
            return state
    }
}

export default form
