// ** Logo
import { useSkin } from '@hooks/useSkin';
const SpinnerComponent = () => {
  const [skin] = useSkin();
  const logo =
    skin === 'dark'
      ? require(`@src/assets/images/logo/logo-white.png`).default
      : require(`@src/assets/images/logo/logo.png`).default;
  return (
    <div className="fallback-spinner vh-100 ">
      <div className="center-fallback loading">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  );
};

export default SpinnerComponent;
