

export const calendarTypes = {
    FetchEvents: '[Calendar] fetch events',
    AddEvent: '[Calendar] Add event',
    RemoveEvent: '[Calendar] Remove event',
    UpdateEvent: '[Calendar] Update event',
    SelectEvent: '[Calendar] Select event',
    UpdateFilter: '[Calendar] Update filter',
    UpdateAllFilters: '[Calendar] Update all filters'
}