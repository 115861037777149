import { formValidatorTypes } from "../../types/validator"


// ** Initial State
const initialState = {
    schema: {},
    errors: []
}

const validator = (state = initialState, action) => {
    switch (action.type) {

        case formValidatorTypes.setSchema:
            return {
                ...state,
                schema: {
                    ...action.payload
                }
            }
        case formValidatorTypes.removeSchema:
            return initialState
        case formValidatorTypes.setErrors:
            return {
                ...state,
                errors: [...action.payload]
            }
        case formValidatorTypes.removeError:
            return {
                ...state,
                errors: [...action.payload]
            }


        case formValidatorTypes.cleanFormValidator:
            return initialState

        default:
            return state
    }
}

export default validator